<template>
  <div class="app">
    <el-table :data="list">
      <el-table-column prop="id" label="订单编号"></el-table-column>
      <el-table-column prop="consignee" label="收货人"></el-table-column>
      <el-table-column
        prop="consigneeCellPhoneNumber"
        label="手机号"
      ></el-table-column>
      <el-table-column
        prop="deliveryAddress"
        label="收货地址"
        width="400px"
      ></el-table-column>
      <el-table-column label="快递公司">
        <template slot-scope="scope">
          <el-select
            v-model="scope.row['logisticsCode_' + scope.$index]"
            filterable
            placeholder="请选择快递公司"
            style="width: 100%"
          >
            <el-option
              v-for="(rs, index) in logisticsInfos"
              :key="index"
              :label="rs"
              :value="index"
            >
            </el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="物流单号">
        <template slot-scope="scope">
          <el-input
            type="text"
            clearable
            placeholder="请输入快递单号"
            v-model="scope.row['trackingNum_' + scope.$index]"
          ></el-input>
        </template>
      </el-table-column>
    </el-table>
    <div class="admin_table_main_pagination">
      <el-card>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-button-group>
              <el-button type="primary" disabled icon="el-icon-arrow-left"
                >导出Excel</el-button
              >
            </el-button-group>
          </el-col>
          <el-col :span="12" style="text-align: right">
            <el-button-group>
              <el-button
                type="primary"
                icon="el-icon-close"
                @click="handleClose"
                >取消</el-button
              >
              <el-button type="primary" icon="el-icon-check" @click="send"
                >确认发货</el-button
              >
            </el-button-group>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Array,
  },
  data() {
    return {
      list: "",
      logisticsInfos: {},
      form: {
        expressage: "",
        tracking_number: "",
      },
    };
  },
  methods: {
    getLogisticsInfos() {
      //获取快递公司
      this.$post(this.$api.logisticsInfos, {}).then((res) => {
        if (res.status == 200) {
          this.logisticsInfos = res.result;
        }
      });
    },
    handleClose() {
      this.dialogSendVisible = false;
      location.reload();
    },
    send() {
      this.$confirm("是否已核对信息并进行发货操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.list.forEach((rs, index) => {
            const postData = {
              logisticsCode: rs["logisticsCode_" + index],
              logisticsCompany: this.logisticsInfos[
                rs["logisticsCode_" + index]
              ],
              trackingNum: rs["trackingNum_" + index],
              id: rs.id,
              orderNo: rs.orderNo,
            };
            this.post(this.$api.trackingsave, postData);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    post(url, data) {
      this.$post(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          // this.$router.go(-1);
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
  },
  created() {
    this.list = this.data;
    this.getLogisticsInfos();
  },
};
</script>
