<template>
  <div class="app">
    <el-dialog
      title="订单发货"
      :visible.sync="dialogSendVisible"
      width="30%"
      :before-close="handleSendClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="收件人">
          <el-input
            type="textarea"
            v-model="form.name"
            disabled
            maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item label="收件地址">
          <el-input
            type="textarea"
            v-model="form.address"
            disabled
            maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="快递公司"
          prop="expressage"
          :rules="[
            { required: true, message: '请选择快递公司', trigger: 'blur' },
          ]"
        >
          <el-select
            v-model="form.expressage"
            filterable
            placeholder="请选择快递公司"
            style="width: 100%"
          >
            <el-option
              v-for="(rs, index) in logisticsInfos"
              :key="index"
              :label="rs"
              :value="index"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="物流单号"
          prop="tracking_number"
          :rules="[
            { required: true, message: '物流单号', trigger: 'blur' },
            {
              min: 10,
              max: 50,
              message: '长度在 10 到 50 个字符',
              trigger: 'blur',
            },
          ]"
        >
          <el-input type="text" v-model="form.tracking_number"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleSendClose">取 消</el-button>
        <el-button type="primary" @click="send('form')">确定发货</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    row: Object,
  },
  data() {
    return {
      dialogCancelVisible: false,
      formCancel: "",
      form: {
        name: "",
        address: "",
        expressage: "",
        tracking_number: "",
        remark: "",
        cancelReason: "",
        exchangeBeanNum: "",
        freight: "",
      },
      dialogSendVisible: false,
      formSend: "",
      logisticsInfos: {},
    };
  },
  methods: {
    send(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              const postData = {
                logisticsCode: this.form.expressage,
                logisticsCompany: this.logisticsInfos[this.form.expressage],
                trackingNum: this.form.tracking_number,
                id: this.row.id,
                orderNo: this.row.orderNo,
              };
              this.post(this.$api.trackingsave, postData);
              this.handleSendClose();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    sendDialog() {
      this.form.name =
        this.row.consignee + " " + this.row.consigneeCellPhoneNumber;
      this.form.address = this.row.deliveryAddress;
      this.form.expressage = this.row.logisticsCompany;
      this.form.tracking_number = this.row.trackingNum;
      this.dialogSendVisible = true;
      //获取快递公司
      this.$post(this.$api.logisticsInfos, {}).then((res) => {
        if (res.status == 200) {
          this.logisticsInfos = res.result;
        }
      });
    },
    post(url, data) {
      this.$post(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          location.reload();
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
    handleSendClose() {
      this.dialogSendVisible = false;
      this.$emit("dialog", false);
    },
  },
  created() {
    this.sendDialog();
  },
};
</script>
