<template>
  <div class="app">
    <div class="header-form">
      <div class="admin_main_block_left">
        <el-form>
          <el-form-item label="类型">
            <el-select v-model="where.examType" placeholder="请选择">
              <el-option label="全部" value=""></el-option>
              <el-option
                v-for="(rs, index) in examType"
                :key="index"
                :label="rs"
                :value="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关键字">
            <el-input
              v-model="where.keyWord"
              placeholder="请输入姓名/账号/ID"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="申请时间">
            <el-date-picker
              v-model="date"
              @change="orderDate" @input="immediUpdate"
              type="monthrange"
              range-separator="~"
              clearable
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
            >
              <i class="el-icon-date"></i>
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" plain icon="el-icon-search" @click="listData()"
              >条件筛选</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="admin_main_block_right">
        <el-row :gutter="20">
          <el-col :span="12"> </el-col>
          <el-col :span="12">
            <el-button
              type="primary"
              @click="exportData"
              disabled
              icon="el-icon-tickets"
              >导出数据</el-button
            >
          </el-col>
        </el-row>
        <div></div>
      </div>
    </div>
    <el-table :data="list" @selection-change="handleSelectionChange">
      <el-table-column type="selection"></el-table-column>
      <el-table-column
        prop="businessId"
        label="ID"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="examType"
        label="类型"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.examType == 0">{{
            examType[scope.row.examType]
          }}</el-tag>
          <el-tag type="warning" v-else-if="scope.row.examType == 1">{{
            examType[scope.row.examType]
          }}</el-tag>
          <el-tag type="danger" v-else-if="scope.row.examType == 2">{{
            examType[scope.row.examType]
          }}</el-tag>
          <el-tag type="primary" v-else-if="scope.row.examType == 3">{{
            examType[scope.row.examType]
          }}</el-tag>
          <el-tag type="info" v-else-if="scope.row.examType == 4">{{
            examType[scope.row.examType]
          }}</el-tag>
          <el-tag v-else>{{ examType[scope.row.examType] }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="agentName"
        label="代理姓名"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="phone"
        label="会员账号（手机号）"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="nickName"
        label="会员名称"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="operationArea"
        label="运营区域"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="serverArea"
        label="服务区域"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column
        prop="applyDate"
        label="申请时间"
        :show-overflow-tooltip="true"
      ></el-table-column>
      <el-table-column prop="" label="状态" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.state == -1">全部</el-tag>
          <el-tag type="danger" v-if="scope.row.state == 0">待审核</el-tag>
          <el-tag type="primary" v-if="scope.row.state == 1">审批成功</el-tag>
          <el-tag type="info" v-if="scope.row.state == 2">审批不通过</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="id" label="操作" fixed="right" width="100px">
        <template slot-scope="scope">
          <el-row>
            <el-col :span="8">
              <el-button
                type="success"
                icon="el-icon-tickets"
                plain
                @click="
                  $router.push({
                    name: 'channelauthDetail',
                    query: {
                      businessId: scope.row.businessId,
                      examType: scope.row.examType,
                    },
                  })
                "
                >查看
              </el-button>
            </el-col>
            <el-col :span="8" v-if="scope.row.state == 2">
              <!--<el-button type="danger" icon="el-icon-del small" plain @click="cancelDialog(scope.row)">
                                删除
                            </el-button>-->
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <div class="admin_table_main_pagination">
      <el-pagination
        @current-change="currentChange"
        @size-change="handleSizeChange"
        background
        :total="totalData"
        :page-size="pageSize"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        layout="sizes, prev, pager, next, total, jumper"
      ></el-pagination>
    </div>
    <cancel :row="row" @dialog="getDialog" v-if="dialogCancelVisible"></cancel>
    <send :row="row" @dialog="getDialog" v-if="dialogSendVisible"></send>
  </div>
</template>
<script>
import cancel from "@/components/admin/mall/order/cancel.vue";
import send from "@/components/admin/mall/order/send.vue";
export default {
  components: {
    cancel,
    send,
  },
  props: {
    orderStatus: Number,
  },
  data() {
    return {
      list: [],
      date: "",
      totalData: 0, // 总条数
      pageSize: 20,
      currentPage: 0,
      row: "",
      selectId: "",
      orderStatusList: [],
      logisticsInfos: [],
      examType: {
        0: "BD",
        1: "服务商",
        2: "运营中心",
        3: "商户审核",
        4: "会员368审核",
      },
      where: {
        pageNum: 0,
        pageSize: 0,
        businessId: "",
        endTime: "",
        examType: "", //"(0:bd审核,1:服务商审核,2:运营中心审核,3:商户审核,4:会员368审核)",
        keyWord: "",
        orderType: "", //"1：根据审核类型排序,2：根据提交者id排序,3：根据创建时间排序",
        startTime: "",
        stateType: "-1", //"-1:全部,0：待审核,1:审批成功,2:审批不通过",
        userId: "",
      },
      dialogCancelVisible: false,
      dialogSendVisible: false,
    };
  },
  // watch: {
  //   dialogSendVisible(newName, oldName) {
  //     console.log(newName, oldName);
  //   },
  // },
  methods: {
    listData() {
      this.where.pageSize = this.pageSize;
      this.where.limit = this.pageSize;
      this.where.shopId = this.shopId;
      this.where.pageNum = this.currentPage;
      this.$post(this.$api.mainexamhistory, this.where).then((res) => {
        if (res.code == 1) {
          this.totalData = res.data.total;
          this.list = res.data.records;
        }
      });
    },
    handleSelectionChange(e) {
      let ids = [];
      e.forEach((v) => {
        ids.push(v);
      });
      this.selectId = ids;
    },
    currentChange(e) {
      this.currentPage = e;
      this.listData();
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.listData();
    },
    orderDate(obj) {
      if (obj) {
        this.where.startTime = obj[0];
        this.where.endTime = obj[1];
      }
    },
    immediUpdate(e) {
      this.$nextTick(() => {
        console.log(e)
        if(e==null){
          this.where.startTime = '';
          this.where.endTime = '';
        }
      });
    },
    exportData() {
      // console.log("exportData");
    },
    sendDialog(row) {
      this.row = row;
      this.dialogSendVisible = true;
    },
    cancelDialog(row) {
      this.row = row;
      this.dialogCancelVisible = true;
    },
    getDialog(val) {
      this.dialogSendVisible = val;
      this.dialogCancelVisible = val;
    },
    batchSend() {
      if (this.$isEmpty(this.selectId)) {
        return this.$message.error("请先选择操作对象");
      }
      this.$emit("get-batch", { data: this.selectId, bool: false });
    },
  },
  created() {
    this.where.stateType = this.orderStatus == -1 ? "" : this.orderStatus;
    this.listData();
  },
};
</script>
<style lang="scss" scoped>
// .admin_main_block_right{
//     /*width: 14%;*/
// }
.admin_main_block_right .el-row {
  width: 100%;
}
.admin_main_block_right div {
  float: left;
}
</style>

