<template>
  <div class="app">
    <el-dialog
      title="取消订单"
      :visible.sync="dialogSendVisible"
      width="30%"
      :before-close="handleSendClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="操作备注" prop="remark">
          <el-input
            type="textarea"
            placeholder="请输入操作原因，以做备忘"
            v-model="form.remark"
            clearable
            maxlength="250"
          ></el-input>
        </el-form-item>
        <el-form-item label="取消原因">
          <el-input
            type="textarea"
            placeholder="请输入取消原因，反馈给用户"
            v-model="form.cancelReason"
            clearable
            maxlength="250"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单惠豆">
          <el-input type="cancelDialog" disabled v-model="form.exchangeBeanNum">
            <template slot="append">惠豆</template>
          </el-input>
        </el-form-item>
        <el-form-item label="订单运费">
          <el-input type="text" disabled v-model="form.freight">
            <template slot="prepend">￥</template>
          </el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleSendClose">取 消</el-button>
        <el-button type="primary" @click="send('form')">确定取消订单</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    row: Object,
  },
  data() {
    return {
      formCancel: "",
      form: {
        remark: "",
        cancelReason: "",
        exchangeBeanNum: "",
        freight: "",
      },
      dialogSendVisible: false,
      formSend: "",
      logisticsInfos: {},
    };
  },
  methods: {
    send(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm("是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              const postData = {
                cancelReason: this.form.cancelReason,
                remark: this.form.remark,
                userId: this.row.userId,
                orderId: this.row.id,
              };
              this.post(this.$api.cancelOrder, postData);
              this.handleSendClose();
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    post(url, data) {
      this.$post(url, data).then((res) => {
        if (res.code == 1) {
          this.$message({
            type: "success",
            message: "成功!",
          });
          location.reload();
        } else {
          this.$message({
            type: "error",
            message: "失败!",
          });
        }
      });
    },
    handleSendClose() {
      this.dialogSendVisible = false;
      this.$emit("dialog", false);
    },
  },
  created() {
    this.dialogSendVisible = true;
    this.form.name =
      this.row.consignee + " " + this.row.consigneeCellPhoneNumber;
    this.form.address = this.row.deliveryAddress;
    this.form.exchangeBeanNum = this.row.exchangeBeanNum;
    this.form.freight = this.row.freight;
    this.form.remark = this.row.remark;
    this.form.cancelReason = this.row.cancelReason;
    this.dialogSendVisible = true;
  },
};
</script>
