var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app"},[_c('el-dialog',{attrs:{"title":"订单发货","visible":_vm.dialogSendVisible,"width":"30%","before-close":_vm.handleSendClose},on:{"update:visible":function($event){_vm.dialogSendVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"收件人"}},[_c('el-input',{attrs:{"type":"textarea","disabled":"","maxlength":"100"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{attrs:{"label":"收件地址"}},[_c('el-input',{attrs:{"type":"textarea","disabled":"","maxlength":"100"},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1),_c('el-form-item',{attrs:{"label":"快递公司","prop":"expressage","rules":[
          { required: true, message: '请选择快递公司', trigger: 'blur' } ]}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"filterable":"","placeholder":"请选择快递公司"},model:{value:(_vm.form.expressage),callback:function ($$v) {_vm.$set(_vm.form, "expressage", $$v)},expression:"form.expressage"}},_vm._l((_vm.logisticsInfos),function(rs,index){return _c('el-option',{key:index,attrs:{"label":rs,"value":index}})}),1)],1),_c('el-form-item',{attrs:{"label":"物流单号","prop":"tracking_number","rules":[
          { required: true, message: '物流单号', trigger: 'blur' },
          {
            min: 10,
            max: 50,
            message: '长度在 10 到 50 个字符',
            trigger: 'blur',
          } ]}},[_c('el-input',{attrs:{"type":"text"},model:{value:(_vm.form.tracking_number),callback:function ($$v) {_vm.$set(_vm.form, "tracking_number", $$v)},expression:"form.tracking_number"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.handleSendClose}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.send('form')}}},[_vm._v("确定发货")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }